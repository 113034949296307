<script>
import axios from 'axios';

export default {
  data() {
    return {
      page      : 1,
      totalPages: 1,
      messages  : [],
      dateMenu: false,
      dates: [],
    };
  },
  created() {
    // Выполнение запроса при первой загрузке страницы
  },
  watch  : {
    // Отслеживаем изменения в параметрах маршрута
    '$route.params.hash': 'fetchMessages'
  },
  computed: {
    // Правило проверки формата (YYYY-MM-DD)
    dateFormatRule() {
      return value => {
        // Разрешаем пустое значение, если хотите это иначе, измените логику
        if (!value) return true;
        const regEx = /^\d{4}-\d{2}-\d{2}$/;
        return regEx.test(value) || 'Введите дату в формате YYYY-MM-DD';
      };
    },
    // Правило валидации, чтобы "from" не была больше "to"
    validFromRule() {
      return value => {
        if (!value || !this.to) return true;
        const fromDate = new Date(value);
        const toDate = new Date(this.to);
        // Если хотя бы одна из дат некорректна, другую логику тоже можно добавить
        if (isNaN(fromDate.getTime())) return 'Некорректная дата';
        if (isNaN(toDate.getTime())) return true;
        return fromDate <= toDate || 'Дата "от" не может быть позже даты "до"';
      };
    },
    // Правило валидации, чтобы "to" не была меньше "from"
    validToRule() {
      return value => {
        if (!value || !this.from) return true;
        const toDate = new Date(value);
        const fromDate = new Date(this.from);
        if (isNaN(toDate.getTime())) return 'Некорректная дата';
        if (isNaN(fromDate.getTime())) return true;
        return toDate >= fromDate || 'Дата "до" не может быть раньше даты "от"';
      };
    },
    dateRangeText() {
      if (this.dates.length < 2){
        return ''
      } 

      // get first and last element
      const [start, end] = this.getPeriod();
      // Форматируем даты в более читаемый вид
      const formattedStart = new Date(start).toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: 'numeric', 
        day: 'numeric'
      });
      const formattedEnd = new Date(end).toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });
      return `${formattedStart} — ${formattedEnd}`;
    },
  },
  methods: {
    getPeriod() {
      if (this.dates.length < 2){
        return this.dates;
      } 
      return [this.dates[0], this.dates[this.dates.length-1]];
    },
    async fetchMessages(page) {
      try {
        if (!Number.isInteger(page)) {
          page = 1;
        }

        const params = {};

        // Заменим логику добавления дат
        if (this.dates.length >= 2) {
          const [start, end] = this.getPeriod();
          params.from = new Date(start).toISOString().split('T')[0];
          params.to = new Date(end).toISOString().split('T')[0];
        }

        params.page = page;

        const hash = this.$route.params.hash;
        if (hash) {
          params.hash = hash;
        }

        const response = await axios.get(`${window.location.origin}/api/report`, {params});
        this.messages.push(...response.data.messages);
        this.page = response.data.page
        this.totalPages = response.data.totalPages
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    },
    clearDateRange() {
      this.dates = []
      this.reset()
      this.fetchMessages(1)
    },
    // Заменим метод filterMessages на обработчик изменения дат
    handleDateChange() {
      console.log(this.dates)
      if (this.dates.length >= 2) {
        this.reset()
        this.fetchMessages(1)
      }
    },
    reset () {
      this.messages = [];
      this.page = 1;
      this.totalPages = 1;
    },
  }
};
</script>

<template>
  <v-container>
    <v-row justify="end">
      
      <v-col cols="12" lg="3" md="4" sm=4 xs="12">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ props }">
            <v-text-field
              v-model="dateRangeText"
              label="Период"
              density="comfortable"
              variant="underlined"
              color="secondary"
              readonly
              v-bind="props"
              clearable
              @click:clear="clearDateRange"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            multiple="range"
            hide-header
            @update:model-value="handleDateChange"
            first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-for="message in messages" :key="message.id">
        <v-card

            :prepend-avatar="'/files/avatars/' + message.chat.photo"
            class="mx-auto"
            :title="message.chat.name"
            :subtitle="message.displayReceivedAt"
        >
          <v-card-text>
            <pre style="white-space: pre-wrap; word-wrap: break-word;">{{ message.content }}</pre>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="teal-accent-4"
                text="Перейти в канал"
                :href="'https://t.me/' + message.chat.name + '/' + message.externalId"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-center">
          <v-btn
              :class="page === totalPages ? 'd-none' : ''"
              color="secondary"
              text="Загрузить ещё"
              @click = "fetchMessages(page+1)"
          ></v-btn>
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>

<style scoped>

</style>